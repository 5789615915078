import Header from "./shared/Header";
import Footer from "./shared/Footer";
import { verifyMagicCode, createMagicCode } from "./shared/api";
import { useState, useRef, forwardRef, useEffect } from "react";
import firebase from "firebase/app";

const standaloneOverride = localStorage.getItem("standalone-override");
const isInStandaloneMode = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  window.navigator.standalone ||
  document.referrer.includes("android-app://");

function LoginContainer({ title, subtitle, children }) {
  return (
    <div>
      <h2 className="text-lg text-center mb-1">{title}</h2>
      <h3 className="text-gray-400 text-center mb-4">{subtitle}</h3>
      {children}
    </div>
  );
}

function VerticalForm({ onSubmit, ...otherProps }) {
  return (
    <form
      className="flex flex-col"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
      {...otherProps}
    />
  );
}

const Input = forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      className="focus:outline-none bg-gray-100 p-2 mb-3 text-black"
      {...props}
    />
  );
});

const Button = forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      className="bg-blue-800 text-white p-2 font-semibold hover:bg-blue-600"
      {...props}
    />
  );
});

function MagicCodeScreen({ onSubmit, isLoading }) {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);
  return (
    <LoginContainer
      title="Paste your magic code"
      subtitle="Nice, you should have received an email with a magic code. Paste it here"
    >
      <VerticalForm onSubmit={() => onSubmit(inputRef.current.value)}>
        <Input ref={inputRef} placeholder="Enter your magic code" />
        <Button type="submit">{isLoading ? "..." : "Verify Magic Code"}</Button>
      </VerticalForm>
    </LoginContainer>
  );
}

function StandaloneScreen() {
  return (
    <LoginContainer title="Welcome" subtitle="Excited to let you in :)">
      <p className="py-4">
        This app is currently private to Consistent members. To get the app you
        must open https://app.consistent.fit on your phone and follow the
        instructions below.
      </p>
      <h4 className="text-lg font-semibold">iOS Instructions</h4>
      <ol className="list-decimal">
        <li className="list-inside">Open up Safari</li>
        <li className="list-inside">Navigate to https://app.consistent.fit</li>
        <li className="list-inside">
          Tap the &quot;Share&quot; icon at the bottom of Safari
        </li>
        <li className="list-inside">
          Tap &quot;Add to Home Screen&quot; from the share tray
        </li>
        <li className="list-inside">
          Tap the &quot;Add&quot; button at the top right of the &quot;Add to
          Homescreen&quot; sheet.
        </li>
      </ol>
      <p className="py-4">
        <a
          className="underline"
          href="https://www.macrumors.com/how-to/add-a-web-link-to-home-screen-iphone-ipad/\"
        >
          Check out this article
        </a>{" "}
        for pictures on steps 3-5.
      </p>
      <h4 className="text-lg font-semibold">Android Instructions</h4>
      <ol className="list-decimal">
        <li className="list-inside">Open up Chrome</li>
        <li className="list-inside">Navigate to https://app.consistent.fit</li>
        <li className="list-inside">
          Tap the menu icon (three dots in the upper right-hand corner)
        </li>
        <li className="list-inside">
          Tap &quot;Add to Home Screen&quot; from the dropdown
        </li>
        <li className="list-inside">
          Tap the &quot;Add&quot; button on the &quot;Add to Home Screen&quot;
          modal
        </li>
      </ol>
      <p className="py-4">
        <a
          href="https://www.howtogeek.com/667938/how-to-add-a-website-to-your-android-home-screen/\"
          className="underline"
        >
          Check out this article
        </a>{" "}
        for pictures on steps 3-5.
      </p>
      <p className="py-4">
        You should now see the Consistent app icon on your phone -- huzzah!
        You'll be able to login after opening the app :)
      </p>
      <p className="py-4">
        If you have any difficulties getting this to work feel free to reach out
        to us on Slack!
      </p>
    </LoginContainer>
  );
}

function EmailScreen({ onSubmit, isLoading }) {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);
  return (
    <LoginContainer title="Welcome" subtitle="Let's log you in">
      <VerticalForm onSubmit={() => onSubmit(inputRef.current.value)}>
        <Input ref={inputRef} placeholder="Enter your email" type="email" />
        <Button type="submit">{isLoading ? "..." : "Get Magic Code"}</Button>
      </VerticalForm>
    </LoginContainer>
  );
}

function ErrorNotice({ message }) {
  return <div className="p-4 bg-yellow-500 mb-4">{message}</div>;
}

export default function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [givenEmail, setGivenEmail] = useState(null);
  const [error, setError] = useState(null);
  const handleError = (data) => {
    setIsLoading(false);
    const errs = data && data.errors && Object.values(data.errors);
    const message = (errs && errs[0]) || "Uh oh, we goofed up. Ping JS";
    setError(message);
  };
  const onMagicCodeSubmit = (code) => {
    setIsLoading(true);
    verifyMagicCode({ code, email: givenEmail })
      .then(({ token }) => {
        return firebase.auth().signInWithCustomToken(token);
      })
      .then(() => {
        setIsLoading(false);
        setError(null);
      })
      .catch(handleError);
  };
  const onEmailSubmit = (email) => {
    setIsLoading(true);
    createMagicCode({ email })
      .then(() => {
        setIsLoading(false);
        setError(null);
        setGivenEmail(email);
      })
      .catch(handleError);
  };
  return (
    <div>
      <Header />
      <div className="p-4">
        <div className="w-80 mx-auto">
          {error && <ErrorNotice message={error} />}
          {(() => {
            if (!isInStandaloneMode() && !standaloneOverride) {
              return <StandaloneScreen />;
            }
            if (givenEmail) {
              return (
                <MagicCodeScreen
                  isLoading={isLoading}
                  onSubmit={onMagicCodeSubmit}
                />
              );
            }
            return (
              <EmailScreen isLoading={isLoading} onSubmit={onEmailSubmit} />
            );
          })()}
        </div>
      </div>
      <Footer />
    </div>
  );
}
