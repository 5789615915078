/*
 * Various helpers shared across modules
 */

import { COHORT_1 } from "./data";

// Misc Helpers
// ---------------------------------------------------------------------------

// rotateArrayToVal([1, 2, 3], 2) => [2, 3, 1]
export const rotateArrayToVal = (arr, val) => {
  const idx = arr.indexOf(val);
  // Return array as is if val is not in array
  if (idx === -1) {
    return arr;
  }

  return arr.slice(idx).concat(arr.slice(0, idx));
};

// zip([1, 2, 3], ["a", "b", "c"]) ->  [[1, 'a'], [2, 'b'], [3, 'c']
// zip([1, 2], ["a", "b", "c"]) ->  [[1, 'a'], [2, 'b']]
// Thanks: https://stackoverflow.com/a/22015930
export const zip = (a, b) => a.map((k, i) => [k, b[i]]);

// range(3) -> [1, 2, 3]
export const range = (n) => new Array(n).fill(1).map((x, idx) => x + idx);

// fill({val: 0, n: 3}) -> [0, 0, 0]
export const fill = ({ val, n }) => new Array(n).fill(val);

// chunk([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 3) => [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10]]
// Thanks: https://stackoverflow.com/a/50766024
export const chunk = (arr, size) => {
  return arr.reduce(
    (acc, _, i) =>
      i % size !== 0 ? acc : acc.concat([arr.slice(i, i + size)]),
    []
  );
};

// Thanks: https://stackoverflow.com/a/17428705
export const transpose = (arr) =>
  arr[0].map((_, colIndex) => arr.map((row) => row[colIndex]));

// defaultMap(["a", "b", "c'"], []) => {"a": [], "b": [], "c": []}
export const defaultMap = (keys, defaultVal) =>
  keys.reduce((res, key) => {
    res[key] = res[key] || defaultVal;
    return res;
  }, {});

// Date Helpers
// ---------------------------------------------------------------------------
// ["01/02/2021", "01/03/2021", "01/01/2021"].sort(dateSort) ->
// [ '01/01/2021', '01/02/2021', '01/03/2021' ]
export const dateSort = (a, b) => new Date(a) - new Date(b);
export const minDate = (dates) => dates.sort(dateSort)[0];
export const maxDate = (dates) => dates.sort(dateSort)[dates.length - 1];

// [["01/02/2021", ...] ["01/03/2021", ...], ["01/01/2021", ...]].sort(dateEntrySort) ->
// [['01/01/2021'], ['01/02/2021', ...], ['01/03/2021',...]]
export const dateEntrySort = ([dateStrA, _entryA], [dateStrB, _entryB]) =>
  dateSort(dateStrA, dateStrB);

export const addDays = (date, days) => {
  const copy = new Date(date);
  copy.setDate(copy.getDate() + days);
  return copy;
};

export const addWeeks = (date, weeks) => {
  return addDays(date, 7 * weeks);
};

export const LOOK_BACK_DAYS = -90;
export const LOOK_AHEAD_DAYS = 10;
export const COHORT_START_DATE = new Date("06/28/2021");
export const FIRST_COHORT_END_DATE = new Date("09/19/2021");
export const COHORT_END_DATE = new Date("02/06/2022");
export const TODAY = new Date();
export const YESTERDAY = addDays(TODAY, -1);

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const SHORT_WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const ORDERED_WEEKDAYS = rotateArrayToVal(SHORT_WEEKDAYS, "Mon");

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MS_TO_MS = 1;
const MS_TO_SEC = MS_TO_MS * 1000;
const MS_TO_MIN = MS_TO_SEC * 60;
const MS_TO_HOURS = MS_TO_MIN * 60;
const MS_TO_DAYS = MS_TO_HOURS * 24;
const MS_TO_WEEKS = MS_TO_DAYS * 7;
const MS_TO_UNIT_MAP = {
  ms: MS_TO_MS,
  sec: MS_TO_SEC,
  min: MS_TO_MIN,
  hours: MS_TO_HOURS,
  days: MS_TO_DAYS,
  weeks: MS_TO_WEEKS,
};

export const START_AFTER_DATE = "06/20/21";
export const GROUP_BY_WEEKDAY = "Monday";

const _timeBetween = (d1, d2, label) => {
  const den = MS_TO_UNIT_MAP[label] || MS_TO_MS;
  return Math.abs(Math.round((new Date(d1) - new Date(d2)) / den));
};

export const daysBetween = (d1, d2) => _timeBetween(d1, d2, "days");
export const weeksBetween = (d1, d2) => _timeBetween(d1, d2, "weeks");

// getWeekDayName("10/11/2020") -> "Sunday"
export const getWeekyDayName = (date) => WEEKDAYS[new Date(date).getDay()];

// getShortWeekDayName("10/11/2020") -> "Sun"
export const getShortWeekyDayName = (date) =>
  SHORT_WEEKDAYS[new Date(date).getDay()];

// 1 -> 1st, 2 -> 2nd, 12 -> 12th, 23 -> 23rd, 29 -> 29th
const getDateSuffix = (day) => {
  if (day <= 0 || day >= 32) {
    return "";
  } // should not happen
  if (day === 1 || day === 21 || day === 31) {
    return "st";
  }
  if (day === 2 || day === 22) {
    return "nd";
  }
  if (day === 3 || day === 23) {
    return "rd";
  }
  return "th";
};

// '5/19/2020' -> May 19th
export const shortFriendlyDate = (dateStr) => {
  // We get a babel error on `npm run build` when destructing like so:
  // const [dateMonth, dateDay, dateYear] = dateStr.split("/");
  // So instead we break apart the pieces manually below *sigh*
  const datePieces = dateStr.split("/");
  const dateMonth = parseInt(datePieces[0], 10) - 1; // Months start from 0
  const dateDay = datePieces[1];
  const dateYear = datePieces[2];
  const date = new Date(dateYear, dateMonth, dateDay);

  const month = SHORT_MONTHS[date.getMonth()];
  const day = `${date.getDate()}${getDateSuffix(date.getDate())}`;

  return `${month} ${day}`;
};

export const friendlyWeek = (date, from = TODAY) => {
  switch (round(weeksBetween(date, from), 0)) {
    case 0:
      return "This week";
    case 1:
      return "Last week";
    default:
      return date;
  }
};

export const friendlyWeekRange = (dateStr, from = TODAY) => {
  const startDateStr = formatDateStr(dateStr);
  switch (Math.floor(weeksBetween(startDateStr, from), 0)) {
    case 0:
      return "This Week";
    case 1:
      return "Last week";
    default:
      const endDateStr = nextWeekDayDate(startDateStr, "Sunday");
      return `${shortFriendlyDate(startDateStr)} — ${shortFriendlyDate(
        endDateStr
      )}`;
  }
};

// Converts date strings from our importer into a js friendly format
// We do this because we use Firebase to store kv pairs of date to value
// but using slashes in FB makes FB create buckets. However using dashes
// for JS can cause weird off by one errors so we marshal the dates when
// doing date manipulation in JS and we unmarshal it back to the importer
// when referring to or saving to FB data
// ex: formatDateStr('2021-06-28') -> '06/28/2021'
export const formatDateStr = (dateStr) => {
  if (dateStr.indexOf("/") > -1) return dateStr;
  const [year, month, day] = dateStr.split("-");
  return `${month}/${day}/${year}`;
};

// 2020-08-16T01:22:00.000Z -> '8/16/2020'
export const extractDate = (date) =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

// Converts client formatted string to importer formated string
// importerDate(06/28/2021') -> '2021-06-28'
export const importerDate = (dateStr) => {
  const date = new Date(dateStr);
  const rawMonth = date.getMonth() + 1;
  const rawDay = date.getDate();

  const month = rawMonth < 10 ? `0${rawMonth}` : rawMonth;
  const day = rawDay < 10 ? `0${rawDay}` : rawDay;
  return `${date.getFullYear()}-${month}-${day}`;
};

// mostRecentWeekDayDate('10/09/2020', 'Monday') -> '10/5/2020'
// mostRecentWeekDayDate('10/09/2020', 'Sunday') -> '10/4/2020'
// mostRecentWeekDayDate('10/05/2020', 'Monday') -> '10/5/2020'
// Thanks: https://stackoverflow.com/a/63495407
export const mostRecentWeekDayDate = (startDate, weekdayName) => {
  // We format the date in a consistent way since the default date format
  // from MFP doesn't play nice with getDay()
  const formattedStartDate = formatDateStr(startDate);
  const target =
    WEEKDAYS.indexOf(weekdayName) !== -1 ? WEEKDAYS.indexOf(weekdayName) : 0; // default to Sunday if invalid weekday name
  let copy = new Date(formattedStartDate);
  copy.setDate(copy.getDate() - ((copy.getDay() + (7 - target)) % 7));
  return copy.toLocaleDateString("en-US");
};

// nextWeekDayDate('10/01/2020', 'Sunday') -> '10/4/2020'
// nextWeekDayDate('10/01/2020', 'Monday') -> '10/5/2020'
// nextWeekDayDate('10/05/2020', 'Monday') -> '10/12/2020'
export const nextWeekDayDate = (startDate, weekdayName) => {
  return mostRecentWeekDayDate(
    addWeeks(startDate, 1).toLocaleDateString("en-US"),
    weekdayName
  );
};

// buildClosedDailyRange(new Date('10/01/20'), new Date('10/03/20')) -> [ '10/1/2020', '10/02/2020', '10/03/2020']
// buildClosedDailyRange(new Date('10/01/20'), new Date('10/01/20')) -> [ '10/1/2020' ]
export const buildClosedDailyRange = (startDate, endDate) => {
  const numDays = Math.floor(daysBetween(startDate, endDate)) + 1;
  return range(numDays).map((x) => extractDate(addDays(startDate, x - 1)));
};

// Math Helpers
// ---------------------------------------------------------------------------
export const perc = (x, y) => (y ? Math.round((x / y) * 100) : 0);
// round(10.23, 0) => 10
const _clean = (items) => items.filter((x) => !isNaN(x));
export const sum = (items) => _clean(items).reduce((xs, x) => (xs += x), 0);
export const avg = (items) => {
  const filtered = _clean(items);
  return filtered.length ? sum(filtered) / filtered.length : null;
};
export const round = (num, precision) =>
  Math.round((num + Number.EPSILON) * Math.pow(10, precision)) /
  Math.pow(10, precision);

// Marhsal Helpers
// ---------------------------------------------------------------------------

// Keep days after given date
// keepDaysAfter(data, '06/27/2021') -> data from '06/28/21' onwards
export const keepDaysAfter = (daysData, afterDate) =>
  Object.entries(daysData)
    .filter(([date, _]) => new Date(date) > new Date(afterDate))
    .reduce((res, day) => {
      const [date, data] = day;
      res[date] = data;
      return res;
    }, {});

export const keepDaysUntil = (daysData, endDate) =>
  Object.entries(daysData)
    .filter(([date, _]) => new Date(date) <= new Date(endDate))
    .reduce((res, day) => {
      const [date, data] = day;
      res[date] = data;
      return res;
    }, {});

export const cleanDays = (daysData) =>
  Object.entries(daysData)
    .filter(([_, data]) => data.totals)
    .reduce((res, day) => {
      const [date, data] = day;
      res[date] = data;
      return res;
    }, {});

export const groupDays = (daysData, weekdayName, endDate) => {
  const allDays = buildClosedDailyRange(COHORT_START_DATE, endDate);
  return allDays.reduce((res, date) => {
    const _importerDate = importerDate(date);
    const data = daysData[_importerDate] || {};
    const groupedDate = mostRecentWeekDayDate(date, weekdayName);
    res[groupedDate] = res[groupedDate] || [];
    res[groupedDate].push([_importerDate, data]);
    return res;
  }, {});
};

export const sortedWeeks = (dateToVal) => {
  return Object.entries(
    groupDays(
      cleanDays(keepDaysAfter(dateToVal, START_AFTER_DATE)),
      GROUP_BY_WEEKDAY,
      YESTERDAY
    )
  )
    .sort(dateEntrySort)
    .map(([_startDateStr, entries]) => entries)
    .map((entries) => entries.sort(dateEntrySort));
};

export const removeCurrentWeek = (sortedWeeks) => {
  const thisMonday = new Date(
    mostRecentWeekDayDate(TODAY.toLocaleDateString("en-US"), GROUP_BY_WEEKDAY)
  );
  return sortedWeeks.filter((days) => {
    const [firstDateStr] = days[0];
    const firstDate = new Date(formatDateStr(firstDateStr));
    return firstDate < thisMonday;
  });
};

// cleanExerciseName("Judo, Karate, Tae-kwon-do") -> "Judo"
// cleanExerciseName("Strength Training (531) High Intensity") -> "Strength Training"
// cleanExerciseName("Apple Health App Workout") -> "Apple Health App"
export const cleanExerciseName = (name) =>
  name.split(/[,(®]/)[0].split(" ").slice(0, 3).join(" ");

// extractDailyExercise -> [[ex1, ex2, ex3, ...], totalExerciseTime]
export const extractDailyExercise = (exercises) => {
  const initial = [[], 0];
  if (!exercises) {
    return initial;
  }
  return exercises
    .filter((x) => !x.name.includes("calorie"))
    .reduce(
      ([names, minutes], x) => [
        [...new Set(names.concat(cleanExerciseName(x.name)))],
        (minutes += x.minutes || 0),
      ],
      [[], 0]
    );
};

export const dailyToWeeklyExerciseSummary = (dailyExercises) => {
  const nameCounts = dailyExercises
    .map(([names, _]) => [...new Set(names)])
    .reduce((xs, x) => xs.concat(x))
    .reduce((xs, x) => {
      xs[x] = xs[x] || 0;
      xs[x] += 1;
      return xs;
    }, {});
  const totalHours = round(
    sum(dailyExercises.map(([_, time]) => time)) / 60,
    1
  );
  const numDays = dailyExercises.filter(([names, _]) => names.length).length;
  return {
    nameCounts,
    numDays,
    totalHours,
  };
};

// Weight Helpers
// -------------------
export function weightInPrefUnits({ lbs, units }) {
  if (units === "kg") {
    return round(lbs * 0.453592, 1);
  }
  return lbs;
}

export const cleanLoggedWeightMap = ({ weightMap, units }) => {
  return Object.keys(weightMap).reduce((xs, x) => {
    xs[x] = weightInPrefUnits({ lbs: parseFloat(weightMap[x]), units });
    return xs;
  }, {});
};

// buildDailyTargetWeights(100, 120, 2) -> [100, 110, 120]
export const buildDailyTargetWeights = (
  startingWeight,
  endingWeight,
  numDays
) => {
  const delta = (endingWeight - startingWeight) / numDays;
  const targets = range(numDays).map((n) =>
    round(startingWeight + delta * n, 1)
  );
  return [startingWeight].concat(targets);
};

export const buildDailyTargetWeightsForProfile = (
  cohort,
  startDate,
  startingWeight,
  targetWeight
) => {
  const dates = buildClosedDailyRange(startDate, COHORT_END_DATE);
  if (cohort !== COHORT_1) {
    return zip(
      dates,
      buildDailyTargetWeights(
        startingWeight,
        targetWeight,
        daysBetween(startDate, COHORT_END_DATE)
      )
    );
  }
  const firstPart = buildDailyTargetWeights(
    startingWeight,
    targetWeight,
    daysBetween(startDate, FIRST_COHORT_END_DATE)
  );

  const secondPart = new Array(
    daysBetween(FIRST_COHORT_END_DATE, COHORT_END_DATE) - 1
  ).fill(targetWeight);

  return zip(dates, firstPart.concat(secondPart));
};

export const buildDailyTargetWeightMap = ({
  cohort,
  startDate,
  startWeight,
  endWeight,
}) => {
  const dailyRange = buildClosedDailyRange(startDate, COHORT_END_DATE);
  const dailyTargets = buildDailyTargetWeightsForProfile(
    cohort,
    startDate,
    startWeight,
    endWeight
  );
  return dailyRange.reduce((xs, x, idx) => {
    xs[importerDate(x)] = dailyTargets[idx];
    return xs;
  }, {});
};
