import siteLogo from "./site-logo.svg";
import UserContext from "./UserContext";
import { useContext } from "react";

function firstName(user) { 
  return user.displayName && user.displayName.split(" ")[0]
}

export default function Header() {
  const user = useContext(UserContext);
  const headerName = user && firstName(user);
  return (
    <div className="p-3 border-b border-gray-700">
      <div className="flex items-center">
        <img src={siteLogo} alt="Consistent Logo" width={34} />
        <div className="pl-2 text-gray-400 flex-1 font-medium uppercase tracking-widest text-sm">
          Be consistent
        </div>
        {headerName && (
          <div className="pl-2 text-gray-400 text-sm">
            <span>👋</span> Heya {headerName}
          </div>
        )}
      </div>
    </div>
  );
}
