export default function Footer() {
  return (
    <div>
      <div className="h-12">
      
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-12 text-gray-400 text-center text-sm flex items-center justify-center">
        Made with determination and love in New York
      </div>
    </div>
  );
}
