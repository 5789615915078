import { useState, useEffect } from "react";
import firebase from "firebase/app";

export default function FirebaseImage({ path, alt, ...restProps }) {
  const [url, setURL] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    setURL(null);
    setError(null);
    firebase
      .storage()
      .ref(path)
      .getDownloadURL()
      .then(
        (url) => {
          setURL(url);
        },
        () => {
          setError("Uh oh");
        }
      );
  }, [path]);
  if (error) {
    return <div>Image Error</div>;
  }
  if (!url) {
    return <div>...</div>;
  }
  return <img alt={alt} src={url} {...restProps} />;
}
