import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAILT7Bvp9RpVZn9qDP2tfIXzSETdmR8nY",
  authDomain: "consistent-mfp.firebaseapp.com",
  databaseURL: "https://consistent-mfp-default-rtdb.firebaseio.com",
  projectId: "consistent-mfp",
  storageBucket: "consistent-mfp.appspot.com",
  messagingSenderId: "214453668275",
  appId: "1:214453668275:web:4c4c7510c86b19e9c7e3c6"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG);
  window.firebase = firebase;
}
