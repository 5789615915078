import { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import UserContext from "./UserContext";
import * as utils from "./utils";

const API_BASE =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8888/api"
    : "https://consistent-app.herokuapp.com/api";

function parseJSON(res) {
  return res.json().then((data) => {
    return res.status === 200 ? data : Promise.reject(data);
  });
}

// Auth
// ----

export function verifyMagicCode(data) {
  return fetch(`${API_BASE}/verify_magic_code`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then(parseJSON);
}

export function createMagicCode(data) {
  return fetch(`${API_BASE}/create_magic_code`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then(parseJSON);
}

// Auth State
// ----------

export function useAuthState() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setIsLoading(false);
      setUser(user);
    });
  }, []);
  return [isLoading, user];
}

// User Data
// ----------

export function useUserData(uid) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setData(null);
    Promise.all([
      firebase
        .database()
        .ref(`/profiles/${uid}`)
        .once("value")
        .then((snap) => snap.val())
        .then((p) => ({ uid, ...p })),
      firebase
        .database()
        .ref(`/data/${uid}`)
        .once("value")
        .then((snap) => snap.val()),
    ])
      .then(([profile, data]) => {
        setError(null);
        setData({ profile, ...data });
        setIsLoading(false);
      })
      .catch((msg) => {
        setIsLoading(false);
        setData(null);
        setError(msg);
      });
  }, [uid]);

  return [isLoading, error, data];
}

// All User Info
// -------------

/*
  Admins can grab data for all user profiles
*/
export function useAllUserProfiles() {
  const [isLoading, setIsLoading] = useState(true);
  const [allUserInfo, setAllUserInfo] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    firebase
      .database()
      .ref("/profiles")
      .once("value")
      .then(
        (data) => {
          setAllUserInfo(
            Object.entries(data.val())
              .map(([uid, p]) => ({ uid, ...p }))
              .filter((x) => !x["archive-date-str"])
          );
          setIsLoading(false);
        },
        () => {
          setError(
            "We weren't able to fetch all user info. You sure you're an admin?"
          );
          setIsLoading(false);
        }
      );
  }, []);
  return [isLoading, error, allUserInfo];
}

export function useLogPageLoad(eventName) {
  const authUser = useContext(UserContext);
  const { uid } = authUser;
  useEffect(() => logEvent({ uid, eventName }), []);
}

export function logEvent({ uid, eventName }) {
  const now = new Date();
  const ds = utils.extractDate(now);
  const ts = now.toISOString();
  const pushRef = firebase.database().ref("/analytics").push();
  pushRef.set({ uid, eventName, ds, ts });
}
