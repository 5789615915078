import * as utils from "./utils";

// Heatmap colors
const WARNING_LEGEND_COLOR = "bg-blue-200";
const TARGET_LEGEND_COLOR = "bg-blue-600";
const DANGER_LEGEND_COLOR = "bg-red-500";
const NO_DATA_LEGEND_COLOR = "bg-white";

// Calorie thresholds
const LOW_CALORIE_THRESHOLD = 0.8;
const TARGET_CALORIE_THRESHOLD = 1.2;

// Protein thresholds
const TARGET_PROTEIN_THRESHOLD = 0.9;

// Exercise thresholds
const LOW_EXERCISE_THRESHOLD = 30;
const TARGET_EXERCISE_THRESHOLD = 60;

// Helpers
// ----
export function colorForCalories({ calories, target }) {
  if (!calories && calories !== 0) return NO_DATA_LEGEND_COLOR;
  // Low, Target, Danger
  return calories < LOW_CALORIE_THRESHOLD * target
    ? WARNING_LEGEND_COLOR
    : calories <= TARGET_CALORIE_THRESHOLD * target
    ? TARGET_LEGEND_COLOR
    : DANGER_LEGEND_COLOR;
}

export function colorForProteins({ proteins, target }) {
  if (!proteins && proteins !== 0) return NO_DATA_LEGEND_COLOR;
  // Target, Danger
  return proteins >= TARGET_PROTEIN_THRESHOLD * target
    ? TARGET_LEGEND_COLOR
    : DANGER_LEGEND_COLOR;
}

export function colorForExercise(time) {
  if (!time && time !== 0) return NO_DATA_LEGEND_COLOR;
  // Danger, Low, Target
  return time < LOW_EXERCISE_THRESHOLD
    ? DANGER_LEGEND_COLOR
    : time < TARGET_EXERCISE_THRESHOLD
    ? WARNING_LEGEND_COLOR
    : TARGET_LEGEND_COLOR;
}

// Text
// ----

export function Heading({ children }) {
  return (
    <div className="mt-8">
      <h2 className="font-semibold text-center text-lg bg-gray-800 py-1">
        {children}
      </h2>
    </div>
  );
}

export function exerciseTimeLabel(time) {
  return time >= 0 ? `${time} min` : "";
}

// Legend
// ------

function LegendItem({ color, label }) {
  return (
    <div className="flex items-baseline space-x-2">
      <div className={`w-3 h-3 ${color} rounded-sm`}></div>
      <div className="text-sm">{label}</div>
    </div>
  );
}

export function Legend({ items }) {
  return (
    <div className="flex space-x-4 justify-center mx-4">
      {items.map(([color, label]) => (
        <LegendItem key={label} color={color} label={label} />
      ))}
    </div>
  );
}

export function WeeklyCaloriesLegend() {
  return (
    <Legend
      items={[
        [WARNING_LEGEND_COLOR, "Low"],
        [TARGET_LEGEND_COLOR, "Target"],
        [DANGER_LEGEND_COLOR, "High"],
        [NO_DATA_LEGEND_COLOR, "No Data"],
      ]}
    />
  );
}

export function ExerciseLegend() {
  return (
    <Legend
      items={[
        [DANGER_LEGEND_COLOR, "<30 min"],
        [WARNING_LEGEND_COLOR, "<60 min"],
        [TARGET_LEGEND_COLOR, "60+ min"],
        [NO_DATA_LEGEND_COLOR, "No Data"],
      ]}
    />
  );
}

// Weekly Navigation
// -----------------

function navigationTextForWeek(week) {
  return utils.friendlyWeekRange(week[0][0]);
}

export function WeeklyNavigation({ weeks, activeWeekIdx, onIdxChange }) {
  const prevWeekIdx = activeWeekIdx - 1;
  const nextWeekIdx = activeWeekIdx + 1;
  return (
    <div className="h-6 my-4 relative weekly-navigation-container">
      <div className="absolute weekly-navigation flex items-center justify-center space-x-8">
        <div className="flex-1 flex justify-end">
          {weeks[prevWeekIdx] && (
            <button onClick={() => onIdxChange(prevWeekIdx)}>
              <span className="font-semibold bg-clip-text text-transparent bg-gradient-to-r from-transparent to-blue-400">
                {navigationTextForWeek(weeks[prevWeekIdx])}
              </span>
            </button>
          )}
        </div>
        <div>
          <div className="font-semibold text-white">
            {navigationTextForWeek(weeks[activeWeekIdx])}
          </div>
        </div>
        <div className="flex-1">
          {weeks[nextWeekIdx] && (
            <button onClick={() => onIdxChange(nextWeekIdx)}>
              <span className="font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-transparent">
                {navigationTextForWeek(weeks[nextWeekIdx])}
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
